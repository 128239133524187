import React, { useEffect, useState, useCallback } from "react"
import { Container, Grid } from "@material-ui/core"
import Gallery from "react-photo-gallery"
import Navbar from "../components/sections/Navbar"
import Hero from "../components/sections/Hero"
import ContactUsSection from "../components/sections/ContactUsSection"
import Carousel, { Modal, ModalGateway } from "react-images"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import Swiper from "../components/Swiper"

const imgGridStyle = {
  display: "grid",
  gridTemplateColumns: `repeat(auto-fill, 400px)`,
}

function getColumnCount() {
  const containerWidth = window.innerWidth

  let columns = 1
  if (containerWidth >= 500) columns = 2
  if (containerWidth >= 900) columns = 3
  // if (containerWidth >= 1200) columns = 4
  // if (containerWidth >= 1500) columns = 5
  return columns
}

function generateColumns(data) {
  const columnCount = getColumnCount()
  const columns = []
  const columnData = JSON.parse(JSON.stringify(data))

  for (var i = 1; i <= columnCount; i++) {
    const imagesPerColumn = data.allFile.edges.length / columnCount
    const isLastColumn = i == columnCount

    let images

    if (isLastColumn) {
      images = columnData.allFile.edges.splice(0, Math.floor(imagesPerColumn))
    } else {
      images = columnData.allFile.edges.splice(0, Math.ceil(imagesPerColumn))
    }

    columns[i - 1] = images
  }

  return columns
}

const GalleryPage = ({ data }) => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    window.addEventListener("resize", () => getColumnsFromData(data))
  }, [])

  const getColumnsFromData = data => {
    const generatedColumns = generateColumns(data)

    if (columns.length == 0) {
      return setColumns(generatedColumns)
    }

    if (generatedColumns.length !== columns.length) {
      return setColumns(generatedColumns)
    }
  }

  const getImagesFromData = data => {
    const columnData = JSON.parse(JSON.stringify(data.allFile.edges))
    
    const mapped = columnData.map(
      x => window.location.origin + x.node.childImageSharp.fluid.src
    )
  }

  useEffect(() => {
    const mappedData = {...data}.allFile.edges.map((image, index) => ({...image, index}))

    getColumnsFromData(data)
    getImagesFromData(data)
  }, [data])

  const [images, setImages] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const handleOpenModal = index => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  return (
    <div className="gallery-page">
      <Navbar />
      <Hero title="Gallery" subtitle="See What We Do!" />
      <Container maxWidth="lg" style={{ minHeight: 600 }}>
        <div className="gallery">
          <Grid container>
            {columns.length > 0 &&
              columns.map((row, rowIndex) => (
                <Grid item xs>
                  {row.map((edge, itemIndex) => (
                    <div
                      onClick={() =>
                        handleOpenModal((itemIndex + 1) * (rowIndex + 1))
                      }
                      className="gatsby-shadow-image"
                    >
                      <Img fluid={edge.node.childImageSharp.fluid} />
                      <img
                        className="gatsby-shadow-image__shadow"
                        src={edge.node.childImageSharp.resize.src}
                      />
                    </div>
                  ))}
                </Grid>
              ))}
          </Grid>
        </div>
      </Container>
      <ContactUsSection />
    </div>
  )
}

// export const query = graphql`
//   query {
//     allImageSharp {
//       edges {
//         node {
//           id
//           fluid(maxWidth: 200, maxHeight: 200) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// `

export const query = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "hall" } }) {
      edges {
        node {
          extension
          dir
          modifiedTime
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 600, quality: 80) {
              src
            }
          }
        }
      }
    }
  }
`

export default GalleryPage
